import * as React from "react"

// External packages
import { Box, BoxProps, Text, Image } from "theme-ui"
import { Zoom, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import { IGatsbyImageData } from "gatsby-plugin-image"

// Hooks
import useLockedBody from "../../../hooks/v2/useLockedBody"
import { useInView } from "../../../hooks/v2/useInView"

// Components
import { Link } from "../Link"
import { Icon } from "../ui/Icon"

// Assets
import "swiper/css"
import "swiper/css/zoom"
import "swiper/css/pagination"

type GalleryImageOwnProps = {
  image: IGatsbyImageData | undefined
  loading?: HTMLImageElement["loading"]
}

const GalleryImage: React.FC<BoxProps & GalleryImageOwnProps> = ({
  image,
  loading,
  ...rest
}) => {
  return (
    <Box as="picture" {...rest}>
      {image?.images?.sources?.map((source, index) => (
        <source
          key={index}
          type={source.type}
          srcSet={source.srcSet}
          sizes={source.sizes}
        />
      ))}
      <Image
        width={image?.width}
        height={image?.height}
        sizes={image?.images?.fallback?.sizes}
        decoding="sync"
        loading={loading || "lazy"}
        src={image?.images?.fallback?.src}
        srcSet={image?.images?.fallback?.srcSet}
        alt=""
        sx={{
          display: "block",
          height: "100%",
          objectFit: "cover",
          transform: "translateZ(0)",
        }}
      />
    </Box>
  )
}

const GalleryIndicator = ({
  isActive,
  ...linkProps
}: {
  isActive?: boolean
}) => {
  return (
    <Link
      {...linkProps}
      sx={{
        display: "block",
        width: "5px",
        height: "5px",
        backgroundColor: isActive ? "primary" : "grayscale.300",
        textIndent: -9999,
        transition: "background-color .2s",
        marginInline: "auto",
        marginBlock: 4,
      }}
    >
      Put image alt here
    </Link>
  )
}

interface GalleryModalProps extends BoxProps {
  isVisible?: boolean
  onCloseClick?: () => void
}

const GalleryModal: React.FC<GalleryModalProps> = ({
  isVisible,
  onCloseClick,
  children,
  sx,
  ...boxProps
}) => {
  return (
    <Box
      {...boxProps}
      sx={{
        ...sx,
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        overflowY: "scroll",
        backgroundColor: "grayscale.100",
        zIndex: "productGalleryModal",
        cursor: "zoom-out",
        opacity: isVisible ? 1 : 0,
        visibility: isVisible ? "visible" : "hidden",
        transition: "opacity .2s, visibility .2s",
      }}
    >
      <Link onClick={onCloseClick} sx={{ position: "fixed", top: 4, right: 4 }}>
        <Icon name="x" />
      </Link>
      {children}
    </Box>
  )
}

export const Gallery = ({
  galleryPhotos,
  modelGender,
  setModelGender,
  currentColor,
  colorOptions,
  isModelSwitcherVisible,
  hasHoverPhotos,
}) => {
  const [locked, setLocked] = useLockedBody(false)
  const [isGenderToggleVisible, setIsGenderToggleVisible] = React.useState(true)
  const [galleryModalId, setGalleryModalId] = React.useState(0)
  const galleryRefs = React.useRef([])
  const observedGalleryElements = useInView(galleryRefs?.current, {
    threshold: 0.5,
  })
  const paginationIndicatorsRef = React.useRef(null)

  React.useEffect(() => {
    if (galleryPhotos) {
      galleryRefs.current = galleryRefs?.current.slice(0, galleryPhotos.length)
    }
  }, [galleryPhotos])

  const isFemale = (gender: string) => {
    if (gender === "female") {
      return true
    }

    return false
  }

  return (
    <>
      <Box
        sx={{
          display: ["none", "block"],
          paddingBlockEnd: Math.round(
            (galleryRefs?.current?.slice(-1)?.[0]?.getBoundingClientRect()
              ?.height -
              60) /
              2 -
              paginationIndicatorsRef?.current?.getBoundingClientRect()
                ?.height +
              48
          ),
        }}
      >
        <Box
          sx={{
            width: [null, 10, 30],
            textAlign: "center",
            position: "sticky",
            top: "50%",
            transform: "translateY(-50%)",
          }}
          ref={paginationIndicatorsRef}
        >
          {Object.keys(observedGalleryElements).map((key, i) => {
            return (
              <GalleryIndicator
                key={i}
                to={
                  Number(key) === 1 ? "#gallery-top" : `#gallery-image-${i + 1}`
                }
                onClick={() => Number(key) === 1 && window.scrollTo(0, 0)}
                isActive={
                  Number(key) - 1 > 0
                    ? !observedGalleryElements[Number(key) - 1].isInView &&
                      observedGalleryElements[key].isInView
                    : observedGalleryElements[key].isInView
                }
              />
            )
          })}
        </Box>
      </Box>
      <Box
        sx={{
          display: [null, "none"],
          width: "100%",
          height: 112,
          position: "sticky",
          top: 0,
          left: 0,
        }}
      >
        <Swiper
          modules={[Pagination, Zoom]}
          pagination={{ el: ".swiper-pagination" }}
          loop={true}
          zoom={true}
          onTouchStart={() => {
            setIsGenderToggleVisible(false)
          }}
          onTouchEnd={() => {
            setIsGenderToggleVisible(true)
          }}
        >
          {galleryPhotos?.map((v, i) => {
            return (
              <SwiperSlide key={i}>
                <Box className="swiper-zoom-container">
                  <Box className="swiper-zoom-target">
                    {modelGender === "female" && (
                      <Box
                        sx={{
                          display: "block",
                        }}
                      >
                        <GalleryImage
                          className="image"
                          image={
                            v.female === undefined
                              ? v?.male?.gatsbyImageData
                              : v?.female?.gatsbyImageData
                          }
                          sx={{
                            display: "block",
                            width: "100%",
                            height: 448,
                          }}
                        />
                      </Box>
                    )}
                    {modelGender === "male" && (
                      <Box
                        sx={{
                          display: "block",
                        }}
                      >
                        <GalleryImage
                          image={v?.male?.gatsbyImageData}
                          className="image"
                          sx={{
                            display: "block",
                            width: "100%",
                            height: 448,
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                </Box>
              </SwiperSlide>
            )
          })}
        </Swiper>
        {isModelSwitcherVisible && (
          <Link
            sx={{
              height: 9,
              display: "flex",
              alignItems: "center",
              gap: 2,
              fontSize: "xs",
              backdropFilter: "blur(20px)",
              visibility: isGenderToggleVisible ? "visible" : "hidden",
              opacity: isGenderToggleVisible ? 1 : 0,
              position: "absolute",
              bottom: 5,
              right: 3,
              zIndex: "modelGenderSwitch",
              transition: "opacity .2s, visibility .2s",
              paddingInline: 3,
            }}
            onClick={() => {
              modelGender === "male" && setModelGender("female")
              modelGender === "female" && setModelGender("male")
            }}
          >
            <Icon name="switch" size={3} />
            <Text>Switch model</Text>
          </Link>
        )}
      </Box>
      <Box
        sx={{
          display: ["none", "flex"],
          flex: (theme) => [
            null,
            `0 0 calc(50% - ${theme.sizes[5]}px)`,
            `0 0 calc(50% - ${theme.sizes[15]}px)`,
          ],
          justifyContent: "center",
          paddingInlineEnd: 10,
          paddingBlockStart: 5,
          paddingBlockEnd: 12,
        }}
      >
        <Box sx={{ position: "relative" }}>
          {hasHoverPhotos && (
            <Box
              sx={{
                width: "100%",
                aspectRatio: "auto 582 / 728",
                position: "absolute",
                top: 0,
                left: 0,
                zIndex: "productImageOnHover",
                pointerEvents: "none",
              }}
            >
              {isModelSwitcherVisible
                ? colorOptions.map((color) =>
                    color?.imagePacks?.map((gender, genderIndex) => {
                      const imagePackGender = gender.isFemale
                        ? "female"
                        : "male"

                      return imagePackGender === modelGender ? (
                        <Box
                          key={color?.imagePacks[genderIndex]?.images?.[0]?.id}
                          sx={{
                            display:
                              color?.color?.title === currentColor &&
                              color?.imagePacks[genderIndex]?.isFemale ===
                                isFemale(modelGender)
                                ? "block"
                                : "none",
                          }}
                        >
                          <GalleryImage
                            className="image"
                            loading="eager"
                            image={
                              color?.imagePacks[genderIndex]?.images?.[0]
                                ?.gatsbyImageData
                            }
                          />
                        </Box>
                      ) : null
                    })
                  )
                : colorOptions.map((color) => (
                    <Box
                      key={color?.imagePacks?.[0]?.images?.[0]?.id}
                      sx={{
                        display:
                          color?.color?.title === currentColor
                            ? "block"
                            : "none",
                      }}
                    >
                      <GalleryImage
                        className="image"
                        loading="eager"
                        image={
                          color?.imagePacks?.[0]?.images?.[0]?.gatsbyImageData
                        }
                      />
                    </Box>
                  ))}
            </Box>
          )}
          {galleryPhotos?.map((v, i) => (
            <Box
              key={i}
              ref={(e) => (galleryRefs.current[i] = e)}
              data-observer-id={i + 1}
              id={`gallery-image-${i + 1}`}
              sx={{
                position: "relative",
                paddingBlockStart: i !== 0 && 15,
                button: {
                  opacity: 0,
                  visibility: "hidden",
                  transition: "opacity .2s, visibility .2s",
                },
                "&:hover button": {
                  opacity: 1,
                  visibility: "visible",
                },
              }}
            >
              <Link
                to={`#gallery-modal-image-${i + 1}`}
                onClick={() => {
                  setGalleryModalId(i + 1)
                }}
                sx={{
                  display: "block",
                  cursor: "zoom-in",
                }}
              >
                {modelGender === "female" && (
                  <Box
                    sx={{
                      display: "block",
                      maxHeight: "calc(100vh - 132px)",
                      aspectRatio: "auto 582 / 728",
                    }}
                  >
                    <GalleryImage
                      className="image"
                      image={
                        v?.female === undefined
                          ? v?.male?.gatsbyImageData
                          : v?.female?.gatsbyImageData
                      }
                    />
                  </Box>
                )}
                {modelGender === "male" && (
                  <Box
                    sx={{
                      display: "block",
                      maxHeight: "calc(100vh - 132px)",
                      aspectRatio: "auto 582 / 728",
                    }}
                  >
                    <GalleryImage
                      className="image"
                      image={v?.male?.gatsbyImageData}
                    />
                  </Box>
                )}
              </Link>
              {isModelSwitcherVisible && (
                <Link
                  sx={{
                    height: 9,
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                    fontSize: "xs",
                    backdropFilter: "blur(20px)",
                    position: "absolute",
                    bottom: 3,
                    left: 3,
                    zIndex: "modelGenderSwitch",
                    paddingInline: 3,
                  }}
                  onClick={() => {
                    modelGender === "male" && setModelGender("female")
                    modelGender === "female" && setModelGender("male")
                  }}
                >
                  <Icon name="switch" size={3} />
                  <Text>Switch model</Text>
                </Link>
              )}
            </Box>
          ))}
        </Box>
      </Box>
      <GalleryModal
        isVisible={galleryModalId !== 0}
        onCloseClick={() => {
          setGalleryModalId(0)
          setLocked(false)
        }}
      >
        <Box
          onClick={() => {
            setGalleryModalId(0)
            setLocked(false)
          }}
          sx={{ paddingInline: "14vw" }}
        >
          {galleryPhotos?.map((v, i) => (
            <Box
              key={i}
              id={`gallery-modal-image-${i + 1}`}
              sx={{ marginBlockEnd: 4 }}
            >
              {modelGender === "female" && (
                <Box sx={{ display: "block" }}>
                  <GalleryImage
                    className="image"
                    sx={{
                      display: "block",
                      maxWidth: "100%",
                      marginInlineStart: "auto",
                    }}
                    image={
                      v?.female === undefined
                        ? v?.male?.gatsbyImageData
                        : v?.female?.gatsbyImageData
                    }
                  />
                </Box>
              )}
              {modelGender === "male" && (
                <Box sx={{ display: "block" }}>
                  <GalleryImage
                    className="image"
                    sx={{
                      display: "block",
                      maxWidth: "100%",
                      marginInlineStart: "auto",
                    }}
                    image={v?.male?.gatsbyImageData}
                  />
                </Box>
              )}
            </Box>
          ))}
        </Box>
        {isModelSwitcherVisible && (
          <Link
            sx={{
              height: 9,
              display: "flex",
              alignItems: "center",
              gap: 2,
              fontSize: "xs",
              backdropFilter: "blur(20px)",
              position: "fixed",
              left: "calc(14vw + 28px)",
              bottom: 7,
              zIndex: "modelGenderSwitch",
              paddingInline: 3,
            }}
            onClick={() => {
              modelGender === "male" && setModelGender("female")
              modelGender === "female" && setModelGender("male")
            }}
          >
            <Icon name="switch" size={3} />
            <Text>Switch model</Text>
          </Link>
        )}
      </GalleryModal>
      <Box
        sx={{
          zIndex: 7000,
          ".swiper-pagination": {
            position: "static",
            display: ["flex", "none"],
            height: "5px",
            marginBlockStart: "-25px",
            marginBlockEnd: 5,
            paddingInline: 3,
          },
          ".swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction":
            {
              bottom: 5,
              left: 3,
            },
          ".swiper-pagination-bullet": {
            display: "block",
            width: "5px",
            height: "5px",
            backgroundColor: "grayscale.white",
            opacity: 0.4,
            borderRadius: 0,
            transition: "background-color .2s, opacity .2s",
          },
          ".swiper-pagination-bullet-active": {
            backgroundColor: "primary",
            opacity: 1,
          },
        }}
      >
        <Box className="swiper-pagination" />
      </Box>
    </>
  )
}
