import * as React from "react"
import { createPortal } from "react-dom"

// External packages
import { navigate } from "gatsby-link"
import { GatsbyImage } from "gatsby-plugin-image"
import { ProductVariant } from "@medusajs/medusa"
import { Box, Image, Text } from "theme-ui"

// Contexts
import { useStore } from "../../../context/NewStoreContext"

// Utilities
import { sortVariants } from "../../../utils/functions/product-util"
import { formatMoneyAmount } from "../../../utils/prices"
import { isMobileWindow } from "../../../utils/render-util"
import { getTitle } from "../utils/product/getTitle"
import { isVariantOutOfStock } from "../utils/product/isVariantOutOfStock"
import { getSizePickerVariant } from "../utils/product/getSizePickerVariant"
import { getMinimumPrice } from "../utils/product/getMinimumPrice"
import { getMaximumPrice } from "../utils/product/getMaximumPrice"
import { isProductOutOfStock } from "../utils/product/isProductOutOfStock"
import { getVariantsInventory } from "../utils/product/getVariantsInventory"

// Hooks
import useLockedBody from "../../../hooks/v2/useLockedBody"
import { useZendesk } from "../../../hooks/v2/useZendesk"
import useWindowSize from "../../../hooks/useWindowSize"
import useDrawerHook from "../../../hooks/useDrawerHook"

// Components
import { ProductButton } from "./ProductButton"
import { SizePickerDrawer } from "./SizePickerDrawer"
import { Link } from "../Link"
import SizeGuide from "../../ProductPage/SizeGuide"
import { handleAddToCart } from "../utils/product/handleAddToCart"
import { getProductTitleWithColor } from "../../../utils/getProductTitleWithColor"

export const RelatedProduct = ({
  product,
  isSizeDrawerOpened,
  setIsSizeDrawerOpened,
  to,
  hasSizePickerDrawer,
  onNotifyMeClick,
  trackRelatedProductClicked,
  collection,
  gender,
  onAddToCart,
}) => {
  const { cart, createLineItem } = useStore()
  const [locked, setLocked] = useLockedBody(false)
  const isMobile = isMobileWindow(useWindowSize()?.width)
  const [openedDrawer, setOpenedDrawer] = React.useState(null)
  const { isActivated, ZendeskWidget } = useZendesk()

  let { variants, categories } = product
  const isSingle = variants?.length < 2
  const hasNotifyMe = !Boolean(product?.disableNotifyMe)

  const [selectedVariant, setSelectedVariant] = React.useState<
    ProductVariant | undefined
  >(variants?.length === 1 ? variants?.[0] : undefined)
  const collectionName = categories.filter((i) => i.parent_category_id)?.[0]
    ?.name

  const sortedVariants = isSingle
    ? variants
    : sortVariants(variants, product.isBedding)

  const formatPrice = () => {
    if (!cart?.id) {
      return ""
    }

    const min = getMinimumPrice(variants)
    const max = getMaximumPrice(variants)

    const priceMin = formatMoneyAmount(
      {
        currencyCode: cart?.region?.currency_code,
        amount: min,
      },
      0
    )

    const priceMax = formatMoneyAmount(
      {
        currencyCode: cart?.region?.currency_code,
        amount: max,
      },
      0
    )

    return max > min ? `${priceMin} - ${priceMax}` : priceMin
  }

  const sizeGuideGroups = product.size_guides
  // Size guide
  const {
    drawerContent: sizeGuideDrawer,
    setDrawerContent: setSizeGuideDrawerContent,
  } = useDrawerHook({ config: { asModal: false } })

  // Size guide use effect if cart changed
  React.useEffect(() => {
    if (sizeGuideGroups?.length) {
      setSizeGuideDrawerContent(
        <SizeGuide
          sizeGuideGroups={sizeGuideGroups}
          initialGender={gender}
          initialSizing={
            cart?.shipping_address?.country_code?.toLowerCase() === "us"
              ? "inch"
              : "cm"
          }
          title={<h2>Size guide</h2>}
        />
      )
    }
  }, [cart?.region_id, gender])

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexBasis: ["calc((((100% - 16px) - 16px) - 4px) / 1.5)", "1"],
          flexGrow: ["0", "1"],
          flexShrink: ["0", "1"],
          position: "relative",
          "&:hover .is-hidden": {
            opacity: 1,
            visibility: "visible",
          },
        }}
      >
        <Box sx={{ position: "relative", marginBlockEnd: 2 }}>
          <Link
            sx={{ display: "block" }}
            onClick={() => {
              trackRelatedProductClicked()
              navigate(to)
            }}
          >
            {product.thumbnail?.gatsbyImageData ? (
              <GatsbyImage
                image={product.thumbnail.gatsbyImageData}
                style={{
                  display: "block",
                  maxWidth: "100%",
                  marginInlineStart: "auto",
                }}
                className="image"
                alt=""
              />
            ) : (
              <Image
                src={product.thumbnail.gatsbyImageData?.images?.fallback?.src}
                sx={{
                  display: "block",
                }}
                alt=""
              />
            )}
          </Link>
          {!hasSizePickerDrawer && sortedVariants?.length > 1 && (
            <Box
              className="is-hidden"
              sx={{
                display: ["none", "flex"],
                flexWrap: "wrap",
                rowGap: 1,
                width: "100%",
                position: "absolute",
                bottom: 0,
                left: 0,
                textAlign: "center",
                opacity: 0,
                visibility: "hidden",
                backdropFilter: "blur(20px)",
                transition: "opacity .2s, visibility .2s",
                paddingBlock: 1,
                "::before": {
                  content: '""',
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  backgroundColor: "grayscale.white",
                  opacity: 0.3,
                  zIndex: -1,
                },
              }}
            >
              {sortedVariants?.map((v) => {
                return (
                  <Text key={v.id} sx={{ flex: "0 0 33.33333%" }}>
                    <Link
                      variant="underline-on-hover"
                      onClick={() => setSelectedVariant(v)}
                      sx={{
                        color: !v?.inventory_quantity && "grayscale.500",
                        textDecoration:
                          !v?.inventory_quantity && "line-through",
                        borderBlockEndColor:
                          v.id === selectedVariant?.id
                            ? "currentColor"
                            : "transparent",
                        pointerEvents:
                          v.id === selectedVariant?.id ? "none" : "unset",
                      }}
                    >
                      {v.sku?.split("-").slice(-1)}
                    </Link>
                  </Text>
                )
              })}
            </Box>
          )}
        </Box>
        <Box
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
          }}
        >
          <Text sx={{ display: "block" }}>{getTitle(product, collection)}</Text>
          <Text sx={{ display: "block", marginBlockEnd: "auto" }}>
            {formatPrice()}
          </Text>
          <Box
            className="is-hidden"
            sx={{
              width: "100%",
              height: "100%",
              position: ["static", "absolute"],
              top: 0,
              left: 0,
              opacity: [1, 0],
              visibility: ["visible", "hidden"],
              backgroundColor: "grayscale.100",
              transition: "opacity .2s, visibility .2s",
              marginBlockStart: [3, 0],
            }}
          >
            <ProductButton
              sx={{ width: "100%" }}
              className="add-to-cart-button"
              onClick={() => {
                if (hasSizePickerDrawer) {
                  setIsSizeDrawerOpened(true)
                  setOpenedDrawer(product.id)
                  setLocked(true)
                } else if (
                  !hasSizePickerDrawer &&
                  isVariantOutOfStock(selectedVariant) &&
                  hasNotifyMe
                ) {
                  onNotifyMeClick(product, selectedVariant)
                } else {
                  if (variants?.length < 2) {
                    handleAddToCart({
                      product: {
                        title: product.title,
                        subcategory: collectionName,
                      },
                      variant: variants?.[0],
                      cart: cart,
                      createLineItem: createLineItem,
                      onAddToCart: onAddToCart,
                      quickAddToCart: true,
                    })
                  } else {
                    handleAddToCart({
                      product: {
                        title: product.title,
                        subcategory: collectionName,
                      },
                      variant: {
                        ...selectedVariant,
                        quantity: 1,
                      },
                      cart: cart,
                      createLineItem: createLineItem,
                      onAddToCart: onAddToCart,
                      quickAddToCart: true,
                    })
                  }
                }
              }}
              hasNotifyMe={hasNotifyMe}
              isProductSoldOut={
                isProductOutOfStock(getVariantsInventory(product)) &&
                hasNotifyMe
              }
              isProductOutOfStock={isProductOutOfStock(
                getVariantsInventory(product)
              )}
              isProductSingleSize={variants?.length < 2}
              isVariantSelected={Boolean(selectedVariant?.id)}
              isVariantOutOfStock={isVariantOutOfStock(selectedVariant)}
              isVariantSoldOut={
                !hasNotifyMe && isVariantOutOfStock(selectedVariant)
              }
              sizePickerVariant={getSizePickerVariant(product)}
              isGiftcard={Boolean(product?.isGiftcard)}
              isMobile={isMobile}
              isProductRelated={true}
              isInDrawer={false}
              isVisuallyDisabled={createLineItem.isLoading}
              isLoading={createLineItem.isLoading}
            />
          </Box>
        </Box>
      </Box>
      {createPortal(
        <>
          {hasSizePickerDrawer && (
            <SizePickerDrawer
              drawerProps={{
                isOpened: isSizeDrawerOpened && openedDrawer === product.id,
                onCloseClick: () => {
                  setIsSizeDrawerOpened(false)
                  setLocked(false)
                  setOpenedDrawer(null)
                },
                onBackdropClick: () => {
                  setIsSizeDrawerOpened(false)
                  setLocked(false)
                  setOpenedDrawer(null)
                },
                sx: { backgroundColor: "grayscale.white" },
              }}
              variants={sortedVariants}
              product={{
                ...product,
                title: getTitle(product, collection) + " - " + product.subtitle,
              }}
              onAddToCart={onAddToCart}
              onNotifyMeClick={() => {
                onNotifyMeClick()
                setIsSizeDrawerOpened(false)
                setOpenedDrawer(null)
              }}
            />
          )}
          {isActivated && <ZendeskWidget />}
          {sizeGuideDrawer}
        </>,
        document.getElementById("drawers-root")
      )}
    </>
  )
}
